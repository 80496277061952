<script setup lang="ts">
import "./v2.css"
import { useAuth } from "#imports"
import { onMounted } from "vue"

const sess = useAuth()

// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => sess.getSession(), 1 * 60 * 60 * 1000)
})
</script>

<template>
  <v-app>
    <v-app-bar elevation="0" class="bg-transparent"></v-app-bar>
    <v-main>
      <Suspense :timeout="500">
        <slot />
        <template #fallback>
          <Loader />
        </template>
      </Suspense>
    </v-main>
  </v-app>
</template>

<style lang="scss">
.header-icon {
  width: 20px;
  height: 20px;
  svg {
    stroke: white;
    fill: white;
  }
}

a.router-link-exact-active {
  color: white;
  font-weight: 400;
}

a.router-link-exact-active::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 30px;
  right: 30px;
  height: 1px;
  background-color: rgb(48, 48, 48);
}

.bottom-navigation {
  background-color: rgb(18, 18, 18);
  z-index: 1050;
  .bottom-navigation-button {
    max-width: unset !important;
  }
}
</style>

